<template>
  <div>
    <v-card
      v-if="atividadesComentarios.length"
      flat
      style="background-color: #f5f5f5"
    >
      <v-card-text class="pa-0">
        <div v-if="search === 'all'">
          <div
            v-for="(atividadeComentario, index) in atividadesComentarios"
            :key="index"
            class="mb-3"
          >
            <div
              v-if="atividadeComentario.tipo == 1"
              class="d-flex"
              style="width: 100%"
            >
              <v-avatar
                class="mr-3 mt-1"
                size="32"
                v-if="atividadeComentario.usuario.avatar"
              >
                <v-img
                  :src="atividadeComentario.usuario.avatar"
                  contain
                ></v-img>
              </v-avatar>
              <v-icon v-else size="32">mdi-account-circle</v-icon>
              <v-card outlined width="85%">
                <v-card-title
                  class="d-flex pt-1 text-caption justify-space-between"
                >
                  <div class="font-weight-bold">
                    {{ atividadeComentario.usuario.name }}
                  </div>
                  <div>
                    {{
                      atividadeComentario.created_at | dateFormat("dd/MM/yyyy")
                    }}
                    às
                    {{
                      atividadeComentario.created_at | dateFormat("HH:mm:ss")
                    }}
                  </div>
                </v-card-title>
                <v-card-text
                  style="line-height: 1.3; font-size: 12px !important"
                  class="pb-1"
                >
                  {{ atividadeComentario.comentario }}
                  <div
                    v-if="isCliente"
                    class="pa-0 d-flex justify-end mr-n2 mt-2"
                    style="font-size: 8px"
                  >
                    CLIENTE
                  </div>
                </v-card-text>
              </v-card>
            </div>
            <div
              class="pa-0 d-flex justify-space-between mr-n2 mt-2"
              style="font-size: 12px"
              v-else
            >
              <div class="">
                {{ atividadeComentario.comentario }}
              </div>
              <div class="" style="min-width: 150px">
                {{ atividadeComentario.created_at | dateFormat("dd/MM/yyyy") }}
                às
                {{ atividadeComentario.created_at | dateFormat("HH:mm:ss") }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="search === 'Histórico'">
          <div
            v-for="(atividadeComentario, index) in atividadesComentarios"
            :key="index"
            class="mb-3"
          >
            <div
              class="pa-0 d-flex justify-space-between mr-n2 mt-2"
              style="font-size: 12px"
              v-if="atividadeComentario.tipo === 0"
            >
              <div class="">
                {{ atividadeComentario.tipo }}
                {{ atividadeComentario.comentario }}
              </div>
              <div class="" style="min-width: 150px">
                {{ atividadeComentario.created_at | dateFormat("dd/MM/yyyy") }}
                às
                {{ atividadeComentario.created_at | dateFormat("HH:mm:ss") }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="search === 'Comentário'">
          <div
            v-for="(atividadeComentario, index) in atividadesComentarios"
            :key="index"
            class="mb-3"
          >
            <div
              v-if="atividadeComentario.tipo === 1"
              class="d-flex"
              style="width: 100%"
            >
              <v-avatar
                class="mr-3 mt-1"
                size="32"
                v-if="atividadeComentario.usuario.avatar"
              >
                <v-img
                  :src="atividadeComentario.usuario.avatar"
                  contain
                ></v-img>
              </v-avatar>
              <v-icon v-else size="32">mdi-account-circle</v-icon>
              <v-card outlined width="85%">
                <v-card-title
                  class="d-flex pt-1 text-caption justify-space-between"
                >
                  <div class="font-weight-bold">
                    {{ atividadeComentario.usuario.name }}
                  </div>
                  <div>
                    {{
                      atividadeComentario.created_at | dateFormat("dd/MM/yyyy")
                    }}
                    às
                    {{
                      atividadeComentario.created_at | dateFormat("HH:mm:ss")
                    }}
                  </div>
                </v-card-title>
                <v-card-text
                  style="line-height: 1.3; font-size: 12px !important"
                  class="pb-1"
                >
                  {{ atividadeComentario.comentario }}
                  <div
                    v-if="isCliente"
                    class="pa-0 d-flex justify-end mr-n2 mt-2"
                    style="font-size: 8px"
                  >
                    CLIENTE
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-card
      class="d-flex align-center justify-center"
      style="background-color: #f5f5f5"
      flat
      v-else
    >
      <v-progress-circular
        v-if="loading === true"
        :width="5"
        indeterminate
        color="primary"
        size="80"
      >
      </v-progress-circular>
      <div>
        <v-sheet style="background-color: #f5f5f5" v-if="loading === false"
          >Nenhum Comentário</v-sheet
        >
      </div>
    </v-card>
  </div>
</template>

<script>
import {
  fetchAtividadeComentario,
  postAtividadeComentario,
} from "@/api/geral/atividades_comentarios.js";
import { mapState, mapGetters } from "vuex";

export default {
  name: "AtividadesComentarios",
  props: {
    atividade_id: {
      type: [Number, String],
      required: true,
    },
    comentario_add: {
      type: [Number, String],
    },
    comentario_tipo: {
      type: Number,
    },
    selected_tipo: {
      type: String,
    },
  },
  components: {},
  data() {
    return {
      loading: true,
      comentario: null,
      atividadesComentarios: [],
      atividadeComentario: {},
      search: "all",
    };
  },
  watch: {},

  computed: {
    ...mapGetters("Usuario", ["isCliente"]),
    ...mapState("Usuario", {
      user_id: (state) => state.usuario.id,
    }),
  },

  methods: {
    getAtividadesComentarios() {
      this.loading = true;
      return fetchAtividadeComentario(this.atividade_id)
        .then((response) => {
          this.atividadesComentarios = response;
          this.loading = false;
          this.$emit("last-scroll");
        })
        .catch((error) => {
          if (error) {
            this.$toast.error("Error");
            this.loading = false;
          }
        });
    },
    addComentario(comentarioTipo) {
      this.loading = true;
      let body = {};
      body.atividade_id = this.atividade_id;
      body.user_id = this.user_id;
      body.comentario = this.comentario;
      body.tipo = comentarioTipo;

      postAtividadeComentario(body)
        .then((response) => {
          if (response.status === 201) {
            this.loading = false;
            this.comentario = null;
            this.getAtividadesComentarios();
            this.$emit("last-scroll");
          }
        })
        .catch((error) => {
          if (error) {
            this.$toast.error("Error");
            this.loading = false;
          }
        });
    },
  },
  async mounted() {
    this.loading = true;
    if (this.selected_tipo) {
      this.search = this.selected_tipo;
    }
    if (this.comentario_add) {
      if (this.comentario_tipo === 0) {
        this.comentario = this.comentario_add;
        this.addComentario(this.comentario_tipo);
      } else {
        this.comentario = this.comentario_add;
        this.addComentario(this.comentario_tipo);
      }
    }
    await this.getAtividadesComentarios();
    this.$emit("last-scroll");
    this.loading = false;
  },
};
</script>

<style lang="sass" scoped></style>
