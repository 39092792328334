import api from "../axios_service.js";

export async function fetchAtividadesComentarios(filtros) {
  const response = await api.get(
    `/atividades-comentarios${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export async function fetchAtividadeComentario(id) {
  const response = await api.get(`/atividades-comentarios/${id}`);
  return response.data.result;
}

export function putAtividadeComentario(id, comentario) {
  let body = {};
  for (let key in comentario) {
    body[key] = comentario[key];
  }
  return api.put(`/atividades-comentarios/${id}`, body);
}

export function postAtividadeComentario(comentario) {
  let body = {};
  for (let key in comentario) {
    body[key] = comentario[key];
  }
  return api.post("/atividades-comentarios/add", body);
}
